import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Breadcrumbs from "../components/breadcrumbs"
import blankPhoneImg from "../images/blank-phone.jpg"
import "../styles/styles.css"

const SecondPage = ({ pageContext: context, location }) => {
  const cityTxt = context.page.city.name ? ` in ${context.page.city.name}` : ""

  var serviceCity
  if (location.state) {
    serviceCity = location.state.serviceCity
  } else {
    serviceCity = "Portland, OR"
  }

  return (
    <Layout serviceCity={serviceCity} repairType={context.page.name}>
      <SEO
        title={`${context.page.name} Repair Services: Broken Screen Repair, Battery Replacement`}
      />
      <section className="section devicesLightBG">
        <div className="container">
          <Breadcrumbs pageName={`${context.page.name}  Repair`} />

          <div className="columns is-multiline">
            <div className="column is-one-half">
              <Link to={`/${context.page.city.url}${context.page.parent.url}`}>
                <h1>
                  {context.page.name} Repair Services {cityTxt}
                </h1>
              </Link>
              <p>
                We provide walk-in {context.page.name} repair service in
                {cityTxt}. In most cases, your broken {context.page.name} repair
                is completed in less than 45 minutes. Whether you need a broken{" "}
                {context.page.name} screen replacement, {context.page.name}{" "}
                battery replacement, speaker or ear replacement, we have the
                parts and service for your {context.page.name}.
              </p>
            </div>
            <div className="column is-one-half">
              <h2>Cities We Repair {context.page.name} Devices</h2>
              {context.page.cities.map(city => {
                return (
                  <div key={city.name}>
                    <Link
                      to={
                        city.url === ""
                          ? `/${city.url}${context.page.parent.url}`
                          : `/${city.url}-${context.page.parent.url}`
                      }
                      state={{ serviceCity: city.name }}
                    >
                      {city.name}
                    </Link>
                  </div>
                )
              })}
            </div>
          </div>
          <br />
          <div className="devicesGrid">
            {Object.keys(context.data).map(k => {
              const phone = { ...context.data[k] }
              return (
                <div className="devicesGridItem" key={phone.name}>
                  <Link
                    to={`/cell-phone-repair/${phone.urlName}/`}
                    state={{ serviceCity }}
                  >
                    {phone.imageUrl ? (
                      <img
                        className="devicesGridImg"
                        src={phone.imageUrl}
                        alt={`${phone.name} Repair Service`}
                      />
                    ) : (
                      <img
                        className="devicesGridImg"
                        src={blankPhoneImg}
                        alt={`${phone.name} Repair Service`}
                      />
                    )}
                    <h2 className="devicesItemHeader">
                      {phone.name} Repair Service
                    </h2>
                  </Link>
                </div>
              )
            })}
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default SecondPage
